import { format } from 'date-fns'
import { useServerTime } from '@/context/ServerTimeContext'

const useIsPastYear = (year) => {
  const serverTime = useServerTime()

  if (!year) {
    return false
  }

  const parsedYear = parseInt(year)
  if (Number.isNaN(parsedYear)) {
    return false
  }

  const currentYear = parseInt(format(serverTime, 'yyyy'))
  return parsedYear < currentYear
}

export default useIsPastYear
